import React from "react";
import { MdOutlineLocalPhone } from "react-icons/md";
import { CiMail } from "react-icons/ci";
import { Outlet } from "react-router-dom";

export default function Layout() {
  return (
    <div className="w-screen h-screen overflow-hidden no-scrollbar">
      <header className="flex h-9 bg-red-600 w-full z-30 text-white justify-evenly items-center px-3">
        <div>
          <p className=" text-xs md:text-sm">
            <MdOutlineLocalPhone className="text-xs sm:text-lg inline-block" />

            <a
              href="tel:+919661393939"
              className=" font-medium  hover:text-blue-500 "
            >
              {" "}
              +91-9661393939,
            </a>
            <br className=" sm:hidden" />
            <a
              href="tel:+919415508787"
              className=" font-medium  hover:text-blue-500"
            >
              {" "}
              +91-9415508787{" "}
            </a>
          </p>
        </div>
        <a href="mailto:contact@rcss.com">
          <p className=" text-xs md:text-sm hover:text-blue-500">
            <CiMail className=" text-xs sm:text-lg inline-block" />{" "}
            <span className=" font-medium  underline">
              info@a1consultancyexpert.com
            </span>
          </p>
        </a>
      </header>
      <Outlet />
    </div>
  );
}
